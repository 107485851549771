import styled, { CSSProperties } from 'styled-components';
import sizes from '../../util/sizes';

interface IMainTitle {
  marginTop?: number;
  mobileMarginTop?: number;
  mobileTextAlign?: CSSProperties['textAlign'];
  lineHeight?: number;
}

export const MainTitle = styled.div<IMainTitle>`
  font-family: BasierCircle-Semibold;
  text-align: center;
  font-weight: 400;
  font-size: 38px;
  color: #263a54;
  margin-bottom: 15px;
  line-height: 29px;
  margin-top: ${({ marginTop = 0 }) => marginTop}px;
  line-height: ${({lineHeight = ''}) => lineHeight}px;
  @media (max-width: ${sizes.S}) {
    justify-content: flex-start;
    text-align: ${({ mobileTextAlign = 'center' }) => mobileTextAlign};
    font-size: 32px;
    margin-top: ${({ mobileMarginTop = 0 }) => mobileMarginTop}px;
  }
`;

export const SubTitle = styled.div<IMainTitle>`
  font-family: BasierCircle;
  text-align: center;
  font-size: 16px;
  color: #263a54;
  margin-bottom: 32px;
  line-height: 18px;
  @media (max-width: ${sizes.S}) {
    text-align: ${({ mobileTextAlign = 'center' }) => mobileTextAlign};
  }
`;

export const RememberMeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;
`;

export const RememberMeText = styled.div`
  font-family: BasierCircle-Medium;
  font-size: 15px;
  color: #263a54;
  font-weight: 400;
`;

export const OrBlockLine = styled.div`
  width: 100%;
  height: 1px;
  border: 0.5px solid #d6dadf;
`;

export const OrText = styled.div`
  margin: 0 8px;
  font-family: BasierCircle-Semibold;
  font-weight: 400;
  font-size: 14px;
`;

export const DontHaveAccTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DontHaveAccText = styled.div`
  margin-right: 6px;
  font-family: BasierCircle;
  font-weight: 400;
  font-size: 15px;
  color: #b2bcca;
`;

export const SignUpText = styled.div`
  margin-right: 6px;
  font-family: BasierCircle-Semibold;
  font-weight: 400;
  font-size: 15px;
  color: #1c4d8e;
  cursor: pointer;
`;

export const OrLineWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 370px;
  margin: 0 auto;
  margin-bottom: 28px;
`;
