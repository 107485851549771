import React, { MouseEvent } from 'react';
import { StyledButton } from './Button.style';

interface IButton {
  text: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
  textColor?: string;
  hasArrow?: boolean;
  marginTop?: string;
  marginBottom?: string;
  marginRight?: string;
  marginLeft?: string;
  id?: string;
  type: 'submit' | 'button';
  disabled?: boolean;
  onBtnClick?: () => void;
}

const Button = ({ text, onBtnClick, ...rest }: IButton) => {
  const btnClickHandler = () => {
    if (onBtnClick) onBtnClick();
  };

  return (
    <StyledButton onClick={btnClickHandler} {...rest} type="submit">
      {text}
    </StyledButton>
  );
};

export default Button;
