import React, { ChangeEvent, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import {
  FieldHeading,
  FieldHeadingWrapper,
  FieldWrapper,
  ForgotPasswordText,
  InputField,
  TextareaField,
  IconImgWrapper,
  ShowPassImgWrapper,
  FieldError,
} from './style';
import EmailLogo from '/public/logo/mailLogo.svg';
import PasswordLogo from '/public/logo/passLogo.svg';
import FullNameLogo from '/public/icons/userIcon.svg';
import PhoneLogo from '/public/icons/phoneIcon.svg';
import ShowPassLogo from '/public//logo/showPass.svg';
import { useDispatch } from 'react-redux';
import { appActions } from '@pickles/shared/redux/actions';

interface IInput {
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeTextarea?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  width?: string;
  height?: string;
  name?: string;
  title?: string;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  type?: 'text' | 'number' | 'email' | 'password' | 'tel';
  isPassword?: boolean;
  placeholder?: string;
  placeholderOnCenter?: boolean;
  iconType?: 'email' | 'password' | 'fullName' | 'phone';
  borderRadius: number;
  color?: string;
  id: string;
  hasForgotPassword?: boolean;
  paddingLeft?: number;
  paddingTop?: number;
  error?: string;
  isTextarea?: boolean;
  required?: boolean;
}

const Input = ({
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  width,
  title,
  value,
  onChange,
  onBlur,
  height,
  borderRadius,
  color,
  iconType,
  isPassword,
  name,
  placeholder,
  placeholderOnCenter,
  type,
  id,
  hasForgotPassword,
  paddingLeft,
  paddingTop,
  error,
  isTextarea,
  onChangeTextarea,
  required,
}: IInput) => {
  const dispatch = useDispatch();
  const [isPassShown, setIsPassShown] = useState(false);

  const showInputIcon = () => {
    switch (iconType) {
      case 'email': {
        return EmailLogo;
      }
      case 'password': {
        return PasswordLogo;
      }
      case 'fullName': {
        return FullNameLogo;
      }
      case 'phone': {
        return PhoneLogo;
      }
      default: {
        return;
      }
    }
  };

  const textareaChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeTextarea?.call(null, event);
  };

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.call(null, event);
  };

  const handleShowPass = () => {
    setIsPassShown(true);
  };

  const handleHidePass = () => {
    setIsPassShown(false);
  };

  const onForgotPasswordClick = () => {
    dispatch(appActions.openResetPasswordForm());
  };

  return (
    <FieldWrapper
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      width={width}
      height={height}
    >
      <FieldHeadingWrapper>
        {title && <FieldHeading>{title}</FieldHeading>}
        {hasForgotPassword && (
          <Link href={'/forgot-password'} passHref={true}>
            <ForgotPasswordText id="forgot_your_password" onClick={onForgotPasswordClick}>
              Forgot your password?
            </ForgotPasswordText>
          </Link>
        )}
      </FieldHeadingWrapper>

      <div style={{ position: 'relative' }}>
        {!!iconType && (
          <IconImgWrapper>
            <Image src={showInputIcon()} alt="input icon" />
          </IconImgWrapper>
        )}
        {isPassword && (
          <ShowPassImgWrapper
            onMouseDown={handleShowPass}
            onMouseUp={handleHidePass}
            hasHeadingTitle={!!title}
          >
            <Image src={ShowPassLogo} alt="password icon" />
          </ShowPassImgWrapper>
        )}
        {isTextarea ? (
          <TextareaField
            placeholder={placeholder}
            onChange={textareaChangeHandler}
            borderRadius={borderRadius}
            value={value}
            id={id}
            name={name}
            paddingLeft={paddingLeft}
            paddingTop={paddingTop}
            error={!!error}
            required={required}
          />
        ) : (
          <InputField
            placeholder={placeholder}
            onChange={inputChangeHandler}
            borderRadius={borderRadius}
            type={isPassShown ? 'text' : type}
            value={value}
            id={id}
            name={name}
            paddingLeft={paddingLeft}
            paddingTop={paddingTop}
            error={!!error}
            onBlur={onBlur}
            required={required}
          />
        )}
      </div>
      {error && <FieldError>{error}</FieldError>}
    </FieldWrapper>
  );
};

export default Input;
