import styled from 'styled-components';
import COLORS from '@pickles/shared/utils/colors';
import sizes from '../../../util/sizes';

interface IButton {
  width?: string;
  height?: string;
  backgroundColor?: string;
  textColor?: string;
  marginTop?: string;
  marginBottom?: string;
  marginRight?: string;
  marginLeft?: string;
  fontSize?: string;
  fontWeight?: string;
  hasArrow?: boolean;
}

export const StyledButton = styled.button<IButton>`
  margin-top: ${({ marginTop = '0' }) => marginTop}px;
  margin-bottom: ${({ marginBottom = '38' }) => marginBottom}px;
  margin-left: ${({ marginLeft = '0' }) => marginLeft}px;
  margin-right: ${({ marginRight = '0' }) => marginRight}px;
  width: ${({ width = '100%' }) => width};
  height: ${({ height }) => height}px;
  background-color: ${({ backgroundColor = COLORS.blumine_main_blue }) => backgroundColor};
  font-weight: ${({ fontWeight = '600' }) => fontWeight};
  color: ${({ textColor = COLORS.white }) => textColor};
  font-size: ${({ fontSize = '17' }) => fontSize}px;
  text-align: center;
  font-family: BasierCircle;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  border: none;

  @media (max-width: ${sizes.S}) {
    max-width: 100%;
    width: 100%;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${({ hasArrow }) =>
    hasArrow &&
    `
  &:after {
    content: url(./icons/btnArrow.svg);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 17px;
  }
  `}
`;
