import styled from 'styled-components';
import sizes from '../../../util/sizes';

interface IStep {
  isActive: boolean;
}

export const Wrapper = styled.div`
  display: flex;
`;

export const ImgBg = styled.div`
  background: url('./images/loginPage.png') 0 0 / cover no-repeat;
  height: 100vh;
  width: 535px;
  @media (max-width: ${sizes.M}) {
    display: none;
  }
`;
export const ContentBlock = styled.div`
  flex-grow: 1;
  // position: relative;
  @media (max-width: ${sizes.M}) {
    padding: 20px;
    height: 100vh;
  }
`;

export const ComponentsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92%;
`;

export const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 26px 32px 0 48px;
  @media (max-width:${sizes.S}){
    justify-content: flex-start;
    margin: 0;
    width: calc(100% - 40px)
    margin-left: auto;
    margin-right: auto;
  }
`;

export const LogoWrapper = styled.div`
  height: 30px;
  @media (max-width: ${sizes.S}) {
    margin-left: 12px;
  }
`;

export const CloseBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: ${sizes.S}) {
    display: none;
  }
`;

export const CloseImg = styled.img`
  width: 18px;
`;

export const HeaderCloseBtn = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: BasierCircle-Semibold;
  letter-spacing: -1px;
  margin-left: 9px;
`;

export const StepWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 32px;
  display: flex;
  width: 172px;
  justify-content: space-between;
`;

export const Step = styled.div<IStep>`
  width: 40px;
  height: 4px;
  border-radius: 2px;
  background-color: ${({ isActive }) => (isActive ? '#1C4D8E' : '#D6DADF')};
`;

export const AuthBackButtonWrapper = styled.div`
  display: none;
  @media (max-width: ${sizes.S}) {
    display: block;
  }
`;
export const ComponentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92%;
`;
