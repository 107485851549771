import React, { FC, ReactNode, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { BackButton } from '../components/Shared/Button/BackButton';
import {
  AuthBackButtonWrapper,
  CloseBtnWrapper,
  ComponentsWrapper,
  ContentBlock,
  HeaderCloseBtn,
  ImgBg,
  LogoWrapper,
  Step,
  StepWrapper,
  Wrapper,
  WrapperHeader,
} from '../components/Shared/SigninSignupWrapper/style';
import picklesIcon from '/public/logo/picklesLogo.svg';
import closeBtnIcon from '/public/logo/closeBtnIcon.svg';

export interface IWithSigninSignup {
  step: number;
  setStep: (value: number) => void;
  setIsSignup: (value: boolean) => void;
}

interface WithSigninSignupProps extends Partial<IWithSigninSignup> {
  children?: ReactNode;
}

const withSigninSignupComposer = (BaseComponent: FC<IWithSigninSignup>) => {
  const WrappedComponent = (props: WithSigninSignupProps) => {
    const router = useRouter();

    const [step, setStep] = useState<number>(0);
    const [isSignup, setIsSignup] = useState(false);

    const handleClose = () => {
      router.push('/');
    };

    return (
      <Wrapper>
        <ImgBg />
        <ContentBlock style={{ position: 'relative' }}>
          <WrapperHeader>
            <AuthBackButtonWrapper>
              <BackButton isHiddenText />
            </AuthBackButtonWrapper>
            <LogoWrapper>
              <Image src={picklesIcon} alt="pickles" />
            </LogoWrapper>
            {step < 4 && (
              <CloseBtnWrapper onClick={handleClose}>
                <Image src={closeBtnIcon} alt="cross" />
                <HeaderCloseBtn>Close</HeaderCloseBtn>
              </CloseBtnWrapper>
            )}
          </WrapperHeader>
          <ComponentsWrapper>
            <BaseComponent {...props} step={step} setStep={setStep} setIsSignup={setIsSignup} />
            {/* {React.Children.map(children, (child) => {
              if (child) {
                return React.cloneElement(child, {
                  step,
                  setStep,
                  setIsSignup,
                });
              }
            })} */}
          </ComponentsWrapper>
          {isSignup && (
            <StepWrapper>
              {step < 4 &&
                Array.from(Array(4).keys()).map((item) => {
                  return <Step key={item} isActive={item === step} />;
                })}
            </StepWrapper>
          )}
        </ContentBlock>
      </Wrapper>
    );
  };

  return WrappedComponent;
};

export default withSigninSignupComposer;
